@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;500;600;700&family=Poppins:wght@500;600&display=swap');
/* @import */
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    line-height: normal;
    font-family: Noto Sans;
    background-color:#272c33;

    color:#fff;
}
.pin-spacer{
    background: none;
}
.owl-carousel{
    background: none;
}
.owl-stage-outer{
    background-color:none;
}
.owl-item{
    background-color:none;
}
html{
    overflow-x:hidden;
    scroll-behavior: smooth;
    /* scroll-margin: 20vh; */
    /* scroll-snap-type: y mandatory; */
}

.scroll-meaning::-webkit-scrollbar{
    display: none;
}

body::-webkit-scrollbar{
    display: none;
}

.owl-dot span{
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-radius: 50% !important;
    border: 0.12vw solid #4CB7EF !important;
}
.owl-dot span:hover{
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-radius: 50% !important;
    background-color: white !important;
}
.owl-dot.active span {
    width: 2.1vw !important;
    height: 2.1vw !important;
    border-radius: 50% !important;
    background-color: #4CB7EF !important;
}








